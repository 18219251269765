@import '../../assets/scss/mixins.scss'; 

.climbing {
    @include wide-container;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem 0;

    h4 {
        font-size: 20px;
    }

    &_wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &_heading {
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin: 0;
            max-width: 70%;
        }

        &_logout {
            background: transparent;
            color: #12c990;
            border: 1px solid #12c990;
            padding: 0.5rem 1rem;

            &:hover {
                background: #d7d7d72c;
            }
        }
    }

    &_selection {
        display: flex;
        gap: 20px;
        width: 100%;

        span {
            width: 50%;
            cursor: pointer;
            position: relative;
            text-align: center;

            &::after {
                content: '';
                width: 100%;
                height: 2px;
                background: transparent;
                position: absolute;
                bottom: -5px;
                right: 0;
            }

            &.active::after {
                background: #12c990;
            }
        }
    }

    &_myroutes {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
    }

    &_climber {
        display: flex;
        justify-content: space-between;

        h4 {
            margin:0;
            font-size: 27px;
        }

        svg {
            width: 25px;
            height: 25px;
            cursor: pointer;
            transition: all 1s;

            &:hover {
                transform: rotate(220deg);
            }
        }
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px 0;

        span:first-child {
            width: 30%;
        }

        span.list_row_number {
            min-width: 20px;
        }

        &_row {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid;
            padding: 15px 5px;
            cursor: pointer;

            &:last-child {
                border-bottom: none;
            }

            &.logedinuser {
                background: #12c99052;
            }
        }

        &_heading {
            font-size: 18px;
            font-weight: 700;
        }
    }
}